import styled from 'styled-components';

// Estilização com styled-components
export const SuppliersContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin: 20px;
 
  /* Estilos gerais */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
}

/* Estilo do formulário */
form {
  display: flex;
  flex-direction: column;
}

input[type="text"],
input[type="number"],
input[type="file"] {
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

/* Campo de busca */
input[type="text"][placeholder="Filtrar fornecedores por nome"] {
  border: 1px solid #ccc;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* Lista de produtos */
ul {
  list-style: none;
  padding: 0;
}

li {
  background-color: #fafafa;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li img {
  width: 100px;
  height: auto;
  border-radius: 4px;
}

li strong {
  font-size: 18px;
  color: #333;
}

li p {
  font-size: 16px;
  color: #555;
}

h2 {
  text-align: center;
}

hr {
  border: 1px solid black; 
  width: 100%; 
  margin: 20px auto;
}

/* Paginação */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  background-color: #008cba;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #007bb5;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button.active {
  background-color: #4caf50;
  cursor: default;
}

/* Loading */
p {
  text-align: center;
  font-size: 18px;
  color: #666;
}

`;
