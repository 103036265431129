import APIError from '../../errors/APIError';
import delay from '../../utils/delay';

class HttpClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get(path, options = {}) {
    return this.makeRequest(path, {
      method: 'GET',
      headers: options?.headers,
      query: options?.query,
    });
  }

  post(path, options = {}) {
    return this.makeRequest(path, {
      method: 'POST',
      body: options?.body,
      headers: options?.headers,
    });
  }

  put(path, options = {}) {
    return this.makeRequest(path, {
      method: 'PUT',
      body: options?.body,
      headers: options?.headers,
    });
  }

  delete(path, options = {}) {
    return this.makeRequest(path, {
      method: 'DELETE',
      headers: options?.headers,
      body: options?.body,
    });
  }

  async makeRequest(path, options) {
    await delay(500);

    const isMultipart = options.body instanceof FormData; // Detecta se o body é FormData
    const headers = new Headers();

    // Adiciona cabeçalhos apenas para JSON
    if (!isMultipart && options.body) {
      headers.append('Content-Type', 'application/json');
    }

    // Adiciona cabeçalhos personalizados
    if (options.headers) {
      Object.entries(options.headers).forEach(([name, value]) => {
        headers.append(name, value);
      });
    }

    const queryString = options.query
      ? '?' + new URLSearchParams(options.query).toString()
      : '';

    const fetchOptions = {
      method: options.method,
      body: isMultipart ? options.body : JSON.stringify(options.body),
      headers: isMultipart ? undefined : headers, // Deixe os cabeçalhos para multipart em undefined
    };

    console.log('Request Options:', fetchOptions);

    const response = await fetch(`${this.baseUrl}${path}${queryString}`, fetchOptions);

    const contentType = response.headers.get('Content-Type');
    let responseBody = null;

    // Processa a resposta com base no tipo de conteúdo
    if (contentType?.includes('application/json')) {
      responseBody = await response.json();
    }

    if (response.ok) {
      return responseBody;
    }

    throw new APIError(response, responseBody);
  }
}

export default HttpClient;
