import { useState, useRef, useEffect } from "react";
import SupplierService from '../../services/SupplierService';
import { SuppliersContainer } from "./styles";

const SuppliersPage = () => {
  const [name, setName] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [suppliersPerPage] = useState(5);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      SupplierService.findAllSuppliers(),
    ]).then(([suppliersResponse]) => {
      // Ordena a lista de categorias em ordem alfabética pelo nome
      const sortedSuppliers = suppliersResponse.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSuppliers(sortedSuppliers);
      setFilteredSuppliers(sortedSuppliers);
      setIsLoading(false);
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const supplierData = {name};

    try {
      const response = await SupplierService.create(supplierData);

      if (response) {
        alert(response.msg);

        // Adiciona o nova categoria à lista, mantendo a ordem alfabética
        const updatedSuppliers = [...suppliers, response.body].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setSuppliers(updatedSuppliers);
        setFilteredSuppliers(updatedSuppliers);

        setName("");

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (error) {
      console.error("Erro ao cadastrar categoria:", error);
      alert("Erro ao cadastrar o categoria. Por favor, tente novamente.");
    }
  };

  // Função para filtrar as categorias com base no termo de busca
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = suppliers.filter(supplier =>
      supplier.name.toLowerCase().includes(value)
    );
    setFilteredSuppliers(filtered);
    setCurrentPage(1);
  };

  // Configurações de paginação
  const indexOfLastCategory = currentPage * suppliersPerPage;
  const indexOfFirstCategory = indexOfLastCategory - suppliersPerPage;
  const currentSuppliers = filteredSuppliers.slice(indexOfFirstCategory, indexOfLastCategory);

  const totalPages = Math.ceil(filteredSuppliers.length / suppliersPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <SuppliersContainer>
      {/* Formulário de Cadastro */}
      <h2>Cadastro de Fornecedores</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button type="submit">Cadastrar Fornecedor</button>
        <hr></hr>
      </form>
  
      {/* Loading */}
      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {/* Campo de Busca */}
          <input
            type="text"
            placeholder="Filtrar fornecedores por nome"
            value={searchTerm}
            onChange={handleSearch}
          />
  
          {/* Lista de Categorias Cadastrados */}
          <h2>Lista de Fornecedores Cadastrados</h2>
          <ul>
            {currentSuppliers.length > 0 ? currentSuppliers.map((supplier) => (
              <li key={supplier._id}>
                <div>
                  <strong>{supplier.name}</strong><br />
                </div>
              </li>
            )) : 
            <li>
                <div>
                  <strong>Sem dados para o filtro especificado!!!</strong><br />
                </div>
              </li>
          }
          </ul>
  
          {/* Paginação */}
          <div className="pagination">
            <button onClick={() => paginate(1)} disabled={currentPage === 1}>
              {'<<'}
            </button>
            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
              {'<'}
            </button>

            {Array.from({ length: Math.min(3, totalPages) }, (_, i) => {
              const pageNumber = Math.max(1, currentPage - 1) + i; // Ajusta o intervalo de páginas

              return (
                <button
                  key={pageNumber}
                  onClick={() => paginate(pageNumber)}
                  className={currentPage === pageNumber ? 'active' : ''}
                  disabled={pageNumber > totalPages} // Não exibe botões se a página for maior que o total
                >
                  {pageNumber}
                </button>
              );
            })}

            <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
              {'>'}
            </button>
            <button onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>
              {'>>'}
            </button>
          </div>
        </>
      )}
    </SuppliersContainer>
  );  
};

export default SuppliersPage;
