import { useState, useRef, useEffect } from "react";
import CategorieService from '../../services/CategorieService';
import { CategoriesContainer } from "./styles";

const CategoriesPage = () => {
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(5);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      CategorieService.findAllCategories(),
    ]).then(([categoriesResponse]) => {
      // Ordena a lista de categorias em ordem alfabética pelo nome
      const sortedCategories = categoriesResponse.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setCategories(sortedCategories);
      setFilteredCategories(sortedCategories);
      setIsLoading(false);
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const categoryData = {name};

    try {
      const response = await CategorieService.create(categoryData);

      if (response) {
        alert(response.msg);

        // Adiciona o nova categoria à lista, mantendo a ordem alfabética
        const updatedCategories = [...categories, response.body].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCategories(updatedCategories);
        setFilteredCategories(updatedCategories);

        setName("");

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (error) {
      console.error("Erro ao cadastrar categoria:", error);
      alert("Erro ao cadastrar o categoria. Por favor, tente novamente.");
    }
  };

  // Função para filtrar as categorias com base no termo de busca
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = categories.filter(category =>
      category.name.toLowerCase().includes(value)
    );
    setFilteredCategories(filtered);
    setCurrentPage(1);
  };

  // Configurações de paginação
  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = filteredCategories.slice(indexOfFirstCategory, indexOfLastCategory);

  const totalPages = Math.ceil(filteredCategories.length / categoriesPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <CategoriesContainer>
      {/* Formulário de Cadastro */}
      <h2>Cadastro de Categorias</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button type="submit">Cadastrar Categoria</button>
        <hr></hr>
      </form>
  
      {/* Loading */}
      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {/* Campo de Busca */}
          <input
            type="text"
            placeholder="Filtrar categorias por nome"
            value={searchTerm}
            onChange={handleSearch}
          />
  
          {/* Lista de Categorias Cadastrados */}
          <h2>Lista de Categorias Cadastradas</h2>
          <ul>
            {currentCategories.length > 0 ? currentCategories.map((category) => (
              <li key={category._id}>
                <div>
                  <strong>{category.name}</strong><br />
                </div>
              </li>
            )) : 
            <li>
                <div>
                  <strong>Sem dados para o filtro especificado!!!</strong><br />
                </div>
              </li>
          }
          </ul>
  
          {/* Paginação */}
        <div className="pagination">
          <button onClick={() => paginate(1)} disabled={currentPage === 1}>
            {'<<'}
          </button>
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            {'<'}
          </button>

          {Array.from({ length: Math.min(3, totalPages) }, (_, i) => {
            const pageNumber = Math.max(1, currentPage - 1) + i; // Ajusta o intervalo de páginas

            return (
              <button
                key={pageNumber}
                onClick={() => paginate(pageNumber)}
                className={currentPage === pageNumber ? 'active' : ''}
                disabled={pageNumber > totalPages} // Não exibe botões se a página for maior que o total
              >
                {pageNumber}
              </button>
            );
          })}

          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            {'>'}
          </button>
          <button onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>
            {'>>'}
          </button>
        </div>
        </>
      )}
    </CategoriesContainer>
  );  
};

export default CategoriesPage;
