import HttpClient from './utils/HttpClient';

class CategorieService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  create(formData) {
    return this.httpClient.post('/bitrom/categories', { body: formData });
  }

  findAllCategories() {
    return this.httpClient.get('/bitrom/categories');
  }
  
}

export default new CategorieService();
