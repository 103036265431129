import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import Header from '../Header';
import Routes from '../../Routes';
import ToastContainer from '../Toast/ToastContainer';

import GlobalStyles from '../../assets/styles/global';
import defaultTheme from '../../assets/styles/themes/default';

import * as S from './styles';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    async function loadApp() {
      const id = document.getElementById('chatbotFBMStore');
      if (id) {
        id.style.display = 'none';
      }
    }

    loadApp();
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <ToastContainer />

        <S.Container>
        {window.location.href.includes("chatbot-page") || window.location.href.includes("bitrom") ? <></> : <Header />}
          <Routes />
        </S.Container>

      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
