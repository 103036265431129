import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import NewChatbot from './pages/NewChatbot';
import NewDialogue from './pages/NewDialogue';
import EditChatbot from './pages/EditChatbot';
import EditDialogue from './pages/EditDialogue';
import EditMenuDialogue from './pages/EditMenuDialogue';
import Dialogues from './pages/Dialogues';
import MenuDialogues from './pages/Dialogues/MenuDialogues';
import ModalChatbotClient from './pages/Dialogues/components/Client/ModalChatbotClient';
import SignUp from './pages/SignUp';
import ActiveAccount from './pages/ActiveAccount';
import Checkout from './pages/Checkout';
import Feedback from './pages/Feedback';
import NewMenuDialogue from './pages/NewMenuDialogue';
import CheckServerIsOn from './pages/CheckServerIsOn';
import TermosDeUso from './pages/TermosDeUso';
import MinhaConta from './pages/MinhaConta';
import TesteMp from './pages/TesteMp';
import SolicitarSenha from './pages/SolicitarSenha';
import RedefinirSenha from './pages/RedefinirSenha';
import ChatbotPage from './pages/ChatbotPage';
import BitromPage from './pages/ProductForm';
import CategoriesPage from './pages/CategoriesPage';
import SuppliersPage from './pages/SuppliersPage';

export default function Routes() {
  return (
    <Switch>
      <Route path="/chatbot" exact component={Login} />
      <Route path="/chatbot/testeMp" exact component={TesteMp} />
      <Route path="/chatbot/home" exact component={Home} />
      <Route path="/chatbot/minha-conta" exact component={MinhaConta} />
      <Route path="/chatbot/termos" exact component={TermosDeUso} />
      <Route path="/chatbot/new" exact component={NewChatbot} />
      <Route path="/chatbot/new/dialogue/:id" exact component={NewDialogue} />
      <Route path="/chatbot/outros/menus/dialogue/:id" exact component={MenuDialogues} />
      <Route path="/chatbot/new/menu/dialogue/:id" exact component={NewMenuDialogue} />
      <Route path="/chatbot/new/menu/:wellcome/dialogue/:id" exact component={NewMenuDialogue} />
      <Route path="/chatbot/edit/:id" exact component={EditChatbot} />
      <Route path="/chatbot/edit/dialogue/:id" exact component={EditDialogue} />
      <Route path="/chatbot/edit/menu-dialogue/:id/:nameMenuParams/:idChatbot" exact component={EditMenuDialogue} />
      <Route path="/chatbot/config/:id" exact component={Dialogues} />
      <Route path="/chatbot/chatbots" exact component={ModalChatbotClient} />
      <Route path="/chatbot/signup" exact component={SignUp} />
      <Route path="/chatbot/signup/active/:token" exact component={ActiveAccount} />
      <Route path="/chatbot/payments/:email" exact component={Checkout} />
      <Route path="/chatbot/pagamentos/feedback" exact component={Feedback} />
      <Route path="/chatbot/check-server" exact component={CheckServerIsOn} />
      <Route path="/chatbot/solicitar-senha" exact component={SolicitarSenha} />
      <Route path="/chatbot/redefinir-senha" exact component={RedefinirSenha} />
      <Route path="/chatbot/chatbot-page" exact component={ChatbotPage} />
      <Route path="/chatbot/bitrom/products" exact component={BitromPage} />
      <Route path="/chatbot/bitrom/categories" exact component={CategoriesPage} />
      <Route path="/chatbot/bitrom/suppliers" exact component={SuppliersPage} />
    </Switch>
  );
}
