import HttpClient from './utils/HttpClient';

class SupplierService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  create(formData) {
    return this.httpClient.post('/bitrom/suppliers', { body: formData });
  }

  findAllSuppliers() {
    return this.httpClient.get('/bitrom/suppliers');
  }
  
}

export default new SupplierService();
