import axios from 'axios';

class BitromService {
  // Faz upload da imagem
  uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post("https://gateway.fbmstore.com.br/uploads", formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log('Upload bem-sucedido:', response.data);
      return response.data;
    } catch (error) {
      console.error('Erro ao enviar imagem:', error.response?.data || error.message);
      throw error;
    }
  };

  // Envia os dados do produto
  productSave = async (productData) => {
    try {
      const productId = productData.id;

      let response;
      if (productId) {
        response = await axios.put(`https://gateway.fbmstore.com.br/bitrom/products/${productId}`, productData, {
          headers: { 'Content-Type': 'application/json' },
        });
      } else {
        response = await axios.post('https://gateway.fbmstore.com.br/bitrom/products', productData, {
          headers: { 'Content-Type': 'application/json' },
        });
      }

      console.log('Produto salvo com sucesso:', response.data);
      return response.data;
    } catch (error) {
      console.error('Erro ao enviar produto:', error.response?.data || error.message);
      throw error;
    }
  };

  // Busca todas as categorias
  findAllCategories = async () => {
    try {
      const response = await axios.get('https://gateway.fbmstore.com.br/bitrom/categories');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar categorias:', error.response?.data || error.message);
      throw error;
    }
  };

  // Busca todos os produtos
  findAllProducts = async () => {
    try {
      const response = await axios.get('https://gateway.fbmstore.com.br/bitrom/products');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar produtos:', error.response?.data || error.message);
      throw error;
    }
  };

  // Busca todos os fornecedores
  findAllSuppliers = async () => {
    try {
      const response = await axios.get('https://gateway.fbmstore.com.br/bitrom/suppliers');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar fornecedores:', error.response?.data || error.message);
      throw error;
    }
  };

  // Deleta uma imagem
  deleteImage = async (imagePath) => {
    try {
      const response = await axios.delete(`https://gateway.fbmstore.com.br/bitrom/uploads/${imagePath}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar imagem:', error.response?.data || error.message);
      throw error;
    }
  };

  // Deleta um produto
  productDelete = async (productId) => {
    try {
      const response = await axios.delete(`https://gateway.fbmstore.com.br/bitrom/delete-product/${productId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar produto:', error.response?.data || error.message);
      throw error;
    }
  };
}

export default new BitromService();
